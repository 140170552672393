<template>
  <div class="inside-page-content">
    <div class="paper">
      <div class="top-search">
        <top-filter
          :typeList="typeList"
          :sortConfig="sortConfig"
          @handleSearchParams="handleSearch"
        ></top-filter>
      </div>
      <div class="pro-list">
        <div class="item" v-for="(t, i) in dataList" :key="i">
          <div class="product">
            <div class="info">
              <div class="title">
                <div class="type">{{t.title}}</div>
              </div>
              <div class="desc">{{t.content}}</div>
              <div class="icon">{{policyTypeDic[t.policyType]}}</div>
            </div>
            <div class="more" @click="handleDetailClick(t.id)">查看详情</div>
          </div>
        </div>
        <div class="footer">
          <div class="left">共 {{total}} 条</div>
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="page.pageNum"
            layout="prev, pager, next"
            :page-size="8"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopFilter from './components/topFilter.vue'
export default {
  name: 'policyList',
  components: {
    TopFilter
  },
  data() {
    return {
      policyTypeDic: { 1: "国家政策", 2: "省级政策" },
      dataList: [],
      total: null,
      page: {
        pageNum: 1,
        pageSize: 8
      },
      queryInfo: {
        keywords: null,
        type: 1
      },
      current: 0,
      keyword: "",
      typeList: [
        {
          name: '政策类型',
          code: 'policyType',
          current: null,
          val: [
            {
              name: '全部',
              val: null,
            },
            {
              name: '国家政策',
              val: 1,
            },
            {
              name: '省级政策',
              val: 2,
            }
          ]
        }
      ],
      sortConfig: [
        {
          name: '发布时间',
          code: 1,
        },
      ]
    }
  },
  computed: {
  },
  mounted() {
    this.handleSearch()
  },
  methods: {
    getList() {
      let params = Object.assign(this.page, this.queryInfo)
      this.$api.technologyFinance.policyList(params).then((res) => {
        let data = res.data;
        this.dataList = data.rows
        this.total = data.total
      })
    },
    handleSearch(params) {
      // 发起请求
      this.queryInfo = params
      // this.queryInfo.keywords = params.keywords
      this.getList();
    },
    handleDetailClick(id) {
      this.$router.push({ path: "/technologyFinance/policyDetail", query: { id: id } });
    },
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getList();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.top-search {
  background: #fff;
  padding: 0 40px;
}
.pro-list {
  background: #fff;
  margin-top: 20px;
  padding: 30px;
  .item {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 0;
    .product {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      height: 150px;
      border: 1px solid #ececec;
      align-items: center;
      .info {
        flex: 0.8;
        display: flex;
        flex-direction: column;
        .title {
          display: flex;
          flex-direction: row;
          .type {
            width: 100%;
            font-weight: 700;
            padding: 10px 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .desc {
          padding: 0 20px;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .icon {
          margin: 10px 20px;
          width: 60px;
          text-align: center;
          font-size: 14px;
          color: #0046c0;
          padding: 3px 10px;
          border: 2px solid #0046c0;
        }
      }
      .more {
        display: inline-block;
        background-color: #0046c0;
        color: #fff;
        padding: 8px 15px;
        border-radius: 15px;
        margin: 10px 20px 20px;
        cursor: pointer;
      }
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #0046c0;
    }
  }
}
</style>
